// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalPadrao_App__SkhcF {\n  text-align: center;\n  padding: 2rem;\n}\n\n.ModalPadrao_webcam__oydo9 {\n  position: relative;\n}\n\n.ModalPadrao_camera__NWJ2p {\n  position: relative;\n}\n\n.ModalPadrao_fundo__2m7pc {\n  position: absolute;\n  width: 70%;\n  height: 60%;\n  top: 20%;\n  left: 15%;\n  border-radius: 50%;\n  background-color: transparent;\n  opacity: .5;\n  rotate: 90deg;\n  box-shadow: 0 0 0 200px rgba(54, 54, 54, 1);\n}", "",{"version":3,"sources":["webpack://./src/Modais/ModalPadrao.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,aAAa;EACb,2CAA2C;AAC7C","sourcesContent":[".App {\n  text-align: center;\n  padding: 2rem;\n}\n\n.webcam {\n  position: relative;\n}\n\n.camera {\n  position: relative;\n}\n\n.fundo {\n  position: absolute;\n  width: 70%;\n  height: 60%;\n  top: 20%;\n  left: 15%;\n  border-radius: 50%;\n  background-color: transparent;\n  opacity: .5;\n  rotate: 90deg;\n  box-shadow: 0 0 0 200px rgba(54, 54, 54, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": "ModalPadrao_App__SkhcF",
	"webcam": "ModalPadrao_webcam__oydo9",
	"camera": "ModalPadrao_camera__NWJ2p",
	"fundo": "ModalPadrao_fundo__2m7pc"
};
export default ___CSS_LOADER_EXPORT___;
